<section class="section p-0 p-sm-3">

    <div class="container mt-32 main-container">
      <!-- <div class="row align-items-center">
        
  
      </div> -->

      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title-credit">
              <div class="card shadow rounded-border border-0 col-8 col-md-7 col-lg-5 text-center-by-margin">
                <!-- <div class="emoji-top"><div class="first-emoji">🥳</div><div class="second-emoji">💰</div></div>    -->
                 <!-- <div class="emoji-top"><div class="first-emoji">🥳</div><div class="second-emoji">💰</div></div>    -->
                <div class="emoji-top">
                  <div class="first-emoji">
                    <img src="assets/images/icono/aprobado1.png" class="emoji" alt="">
                  </div>
                  <div class="second-emoji">
                    <img src="assets/images/icono/aprobado2.png" class="emoji" alt="">
                  </div>
                </div>   
                <!-- <img src="assets/images/icono/aprobado1.png" class="emoji" alt=""> -->
                <!-- <img src="assets/images/icono/aprobado2.png" class="emoji" alt="">
                <img src="assets/images/icono/aprobado3.png" class="emoji" alt="">
                <img src="assets/images/icono/aprobado4.png" class="emoji" alt=""> -->
                <div class="card-body bg-light p-3 p-sm-3 p-md-3">
                    <div class="text-center">
                        <h1 class="title-credito">CRÉDITO APROBADO,</h1>
                        <h1 class="title-credito">RETIRA TU DINERO,</h1>
                    </div>
                </div>
                <!-- <div class="emoji-bottom">
                  <div class="first-emoji">🎉</div><div class="second-emoji">🤑</div>
                </div> -->

                <div class="emoji-bottom">
                  <div class="first-emoji">
                    <img src="assets/images/icono/aprobado3.png" class="emoji" alt="">
                  </div>
                  <div class="second-emoji">
                    <img src="assets/images/icono/aprobado4.png" class="emoji" alt="">
                  </div>
                </div> 
              </div>
          </div>

              <!-- <div class="mb-4 pb-2 icon-container">
                <div class="icon-credito">
                  <img class="img-phone" src="./../../../../assets/images/creditos/phoneimg.png" alt="Phone Icon">
                  <p class="text-justify mb-0 mx-auto">Descarga la app y desembolsa tu crédito en nuestra <b>billetera digital 24/7 sin ir a una sucursal.</b></p>
              </div>
  
                <div class="icon-credito">
                  <img class="img-moneycash" src="./../../../../assets/images/creditos/moneycash.png" alt="Money Icon">
                  <p class="text-justify mb-0 mx-auto">Vas a poder <b>retirar efectivo</b> en cualquier cajero de la red infonet usando el dinero en tu billetera.</p>
              </div>
            </div>
  
            <div class="pt-4 mb-4 pb-2 pasos-container">
                  <h4 class="mb-4">Seguí estos pasos:</h4>
                  <ol>
                      <li>Descarga la app</li>
                      <li>Create una cuenta</li>
                      <li>Valida tu identidad</li>
                      <li>Activá la billetera</li>
                      <li>Desembolsa tu crédito </li>
                  </ol>
            </div> -->

          <div class="section-title-credit mb-4 col-md-8 col-xs-10 col-sm-10 mt-4">
            <!-- <h2 class="title-credit mb-4">
              Descargá la app y recibí tu dinero en nuestr billetera diginal.
              Podes <span class="bg-orange">retirar efectivo</span> en cualquier cajero de la red infonet.
            </h2> -->
            <h5 class="title-credit-text mb-4">
              Descargá la app y recibí tu dinero en nuestra billetera diginal.
              Podes <span class="bg-orange">retirar efectivo</span> en cualquier cajero de la red infonet.
            </h5>
            <!-- <h3 class="title-credit mb-4">Necesitamos más <span class="bg-orange">datos</span> para seguir con tu solicitud!</h3> -->
          </div>
  
            <div class="descargar-container">
              <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL"
                target="_blank"
                title="Descarga APP"
                type="button"
                (click)="onDescargarApp.emit(true)"
                class="btn btn-pills btn-primary mb-0"><b>DESCARGAR APP</b></a>
            </div>


            <div class="retiro-dinero">
              <p class="text-center mb-0 mx-auto">También podés retirar el dinero en una de nuestras sucursales.</p>
              <!-- <button type="button" (click)="irASucursales()" class="btn btn-pills btn-primary mb-0 ver-sucursales"><b>VER SUCURSALES</b></button> -->
              <button type="button" (click)="onVerSucursales.emit(true)" class="btn btn-pills btn-primary mb-0 ver-sucursales"><b>VER SUCURSALES</b></button>
            </div>
        </div>
      </div>
    </div>
  </section>