import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'primerNombreApellido'
})
export class PrimerNombrePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    // Dividir el nombre completo en partes
    const partes = value.trim().toLowerCase().split(/\s+/);

    if (partes.length === 0) {
      return ''; // Si no hay texto, devolvemos una cadena vacía
    }

    // Capitalizar y devolver el primer nombre
    return this.capitalizar(partes[0]);
  }

  private capitalizar(texto: string): string {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }
}
