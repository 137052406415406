<div class="faker-container">
    <h1 class="mis-solicitudes">Mis solicitudes</h1>


<div class="table-responsive">
  <table class="table table-striped mt-4">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Monto</th>
        <th scope="col">Cuota</th>
        <th scope="col">Nro Cuotas</th>
        <th scope="col">Estado</th>
        <th scope="col">Fecha Creación</th>
        <th scope="col">Acciones</th>
      </tr>
    </thead>
    <tbody>
        <!-- @for (item of dataSource; track item.idSolicitud) {
          <tr>
            <th scope="row">1</th>
            <td>{{item.montoSolicitado | thousandSeparator}}</td>  
            <td>{{item.cuota | thousandSeparator}}</td>  
            <td>{{item.numeroCuotas}}</td>  
            <td [ngStyle]="{'color': item.color}">
              {{item.estado}}
            </td>  
            <td>{{item.creacion}}</td>  
            <td><i role="button" title="Ver detalles" class="bi bi-eye" (click)="onDetail(item)"></i></td>
          </tr>  
        } -->

      
      </tbody>
    </table>
</div>


<!-- MODAL APROBADO MEDIO 140 - BINDER - DIGITAL -->




<!-- MODAL -->
<ng-template #modalPermissionRequest let-modal>
  <div class="modal-content modal-notificaciones rounded shadow border-0">
    <div class="modal-body-permission py-2">
      <!-- <img src="assets/images/icono/aprobado2.png" class="emoji" alt=""> -->
      <!-- <img class="emoji" src="./../../../../assets/images/creditos/phoneimg.png" alt="Phone Icon"> -->
      <div class="img-container bg-primary py-4">
        <img class="bg-primary emoji" src="assets/images/logo_hv.png" alt="CrediAgilLogo">
      </div>
      <div class="moda-sub-body">
        <!-- <img src="assets/images/{{icono}}.png" class="avatar avatar-medium rounded-circle shadow" alt=""> -->
        <div class="modal-contenido mt-4">
          <h4><i class="bi bi-bell"></i> <span>Notificaciones</span></h4>
          <p class="text-justify noti-subtitle">Te gustaria recibir notificaciones del estado de tus solicitudes? Dale clic en aceptar y permitir notificaciones.</p>
          <div class="mt-4 buttons-modal">
            <a class="btn text-danger mr-1" (click)="modal.close('cancel')">No, gracias</a>
            <a class="btn btn-primary ml-1" (click)="modal.close('aceptar')">Aceptar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
</div>