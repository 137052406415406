import { afterNextRender, Component, ElementRef, inject, signal, Signal } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {NgForOf} from "@angular/common";
import { Comunicacion } from './model';
import { ConmunicationsService } from '../../services/conmunications.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-communications',
  standalone: true,
  imports: [
    NgxDocViewerModule,
    NgForOf
  ],
  templateUrl: './communications.component.html',
  styleUrl: './communications.component.css'
})
export class CommunicationsComponent {
  toastService = inject(ToastService);
  contentListSignal = signal<Comunicacion[]>([]);
  cargando = true;

  /**
   * contentList = [
    {
      "titulo": 'Llega "Fredi Clú"',
      "descripcion":"la tarjeta de descuentos para los principales comercios está a tu alcance con Credi Ágil.",
      "link_pdf":"assets/documents/info_fredi_clu_para_clientes.pdf",
      "archivos_adicionales": [
        {
          "tipo": "Sitio web de Fredi clú",
          "name": "Acceder",
          "link_descarga": "http://www.frediclu.com.py/"
        }
      ],
    },
    {
      "titulo": 'Promoción "Día del niño" - Credi Ágil',
      "link_pdf":"assets/documents/BASES_Y_CONDICIONES_DÍA_DEL_NIÑO_CREDI_AGIL.pdf",
      "archivos_adicionales": [
        {
          "tipo": "Bases y Condiciones",
          "name": "Descargar PDF",
          "link_descarga": "assets/documents/BASES_Y_CONDICIONES_DÍA_DEL_NIÑO_CREDI_AGIL.pdf"
        }
      ],
    },
    {
      "titulo": 'Festejá con Credi Ágil y ganá premios para mamá y papá',
      "link_pdf":"assets/documents/BASES_Y_CONDICIONES_PROMO_MAMA_Y_PAPA_CA-1.pdf",
      "archivos_adicionales": [
        {
          "tipo": "Bases y Condiciones",
          "name": "Descargar PDF",
          "link_descarga": "assets/documents/BASES_Y_CONDICIONES_PROMO_MAMA_Y_PAPA_CA-1.pdf"
        }
      ],
    },
    {
      "titulo": '“Cumplí y ganá” – Bases y condiciones',
      "link_pdf": 'assets/documents/Amigo_Agil_Bases_y_Condiciones.pdf',
      "archivos_adicionales": [
        {
          "tipo": "Amigo Agil Bases y Condiciones",
          "name": "Descargar PDF",
          "link_descarga": "assets/documents/Amigo_Agil_Bases_y_Condiciones.pdf"
        }
      ],
    }
  ]
   */

  constructor(private conmunicationsService: ConmunicationsService,
    public sanitizer: DomSanitizer,
    private el: ElementRef
  ) {
    afterNextRender(() => {
      this.cargarList();
    });
  }

  ngOnInit(): void {
    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

  async cargarList(){
    const comunicacionesList = await this.getListComunicaciones();
    this.contentListSignal.set(comunicacionesList ?? []);
  }

  async getListComunicaciones(){
    try {
      this.cargando = true;
      const comunicacionesList = await this.conmunicationsService.getListComunicaciones();
      this.cargando = false;
      return comunicacionesList;
    } catch (error: any) {
      this.toastService.show('error', error.error?.message);
      this.cargando = false;
      return null;
    }
  }
}
