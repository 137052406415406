import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MisSolicitudesService {
  #misSolicitudes = signal<any []>([])
  solicitudesSignal = this.#misSolicitudes.asReadonly();

  constructor() { }
  
  /**
   * Setea la lista de solicitudes 
   * @param misSolicitudes 
   */
  setMisSolicitudes(misSolicitudes: any[]){
    this.#misSolicitudes.set(misSolicitudes);
  }


  /**
   * Actualiza la solicitud notificada dentro de la lista de misSolicitudes
   * @param solicitudNotificadaToUpdate 
   */
  updateSolicitud(solicitudNotificadaToUpdate: any){
    const misSolicitudes = this.#misSolicitudes();

    const misSolicitudesNew = misSolicitudes.map(solicitud => (
      solicitud.idSolicitud === solicitudNotificadaToUpdate.solicitudId ? 
                        {...solicitud, estado: solicitudNotificadaToUpdate.estado, color: solicitudNotificadaToUpdate.color}
                         : solicitud
    ));

    this.#misSolicitudes.set(misSolicitudesNew);
  }
}
