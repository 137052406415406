<!-- TOOLBAR -->
<!-- <p class="pb-0 mb-0 bg-blue-400"> -->
    <app-toolbar></app-toolbar>
  <!-- </p> -->
  <!--FIN TOOLBAR -->
  
  <div class="example-container" autosize>
  
      <!--SIDEBAR-->
      <div class="example-sidenav example-sidenav-toggle" mode="side">
        <app-nav-menu></app-nav-menu>
      </div>
      <!-- FIN SIDEBAR-->
    
      <!-- MAIN WRAPPER -->
      <div class="example-sidenav-content">
          <main class="pb-14">
            <app-loader />

            <router-outlet>
            </router-outlet>
          </main>
      </div>
      <!-- FIN MAIN WRAPPER -->
    
  </div>


  <!-- MODAL APROBADO MEDIO 140 - BINDER - DIGITAL -->
<ng-template #modalAprobado let-modal>
  <div class="modal-content modal-aprobado rounded shadow border-0">
    <div class="modal-body py-2">
      <div>
        <button type="button" (click)="modal.close('aceptar')" class="btn btn-danger mb-0 close-button"><b>X</b></button>
      </div>
      <app-aprobado 
          (onVerSucursales)="onCloseModal($event); onVersSucursales()"
          (onDescargarApp)="onCloseModal($event);"/>
    </div>
  </div>
</ng-template>

<ng-template #modalAprobadoConPagare let-modal>
  <div class="modal-content modal-aprobado rounded shadow border-0">
    <div class="modal-body py-2">
      <div>
        <button type="button" (click)="modal.close('aceptar'); cleanData();" class="btn btn-danger mb-0 close-button"><b>X</b></button>
      </div>
          <app-aprobado-medio 
            (onIrAWhatsapp)="onCloseModal($event)"
            (onVerSucursales)="onCloseModal($event); onVersSucursales()"/>
    </div>
  </div>
</ng-template>

  <app-footer-dashboard />
  