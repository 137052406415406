import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer-dashboard',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './footer-dashboard.component.html',
  styleUrl: './footer-dashboard.component.css'
})
export class FooterDashboardComponent implements OnInit {
  year = new Date().getFullYear()
  constructor() {
  }

  ngOnInit(): void {
  }
}