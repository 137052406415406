import { AfterViewInit, Component, ElementRef, inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { ToastsContainer } from '../toast-container/toast-container.component';

@Component({
  selector: 'ngbd-toast-global',
  standalone: true,
  imports: [ToastsContainer],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css'
})
export class ToastComponent implements OnDestroy, AfterViewInit {
	toastService = inject(ToastService);
	@ViewChild('standardTpl', {static: false}) standar?: ElementRef;
	@ViewChild('toastSuccess', {static: false}) toastSuccess?: ElementRef;
	@ViewChild('toastError', {static: false}) toastError?: ElementRef;
	@ViewChild('toastBar', {static: false}) toast?: ElementRef;

	showStandard(template: TemplateRef<any>) {
		// this.toastService.show({ template, classname: '', delay: 3500 });
	}

	showSuccess(template: TemplateRef<any>) {
		// this.toastService.show({ template, classname: 'bg-success text-light', delay: 3500 });
	}

	showDanger(template: TemplateRef<any>) {
		// this.toastService.show({ template, classname: 'bg-danger text-light', delay: 3500 });
	}

	ngOnDestroy(): void {
		this.toastService.clear();
	}

  ngAfterViewInit(): void {
      this.toastService.setRef(this.toast);
  }
}