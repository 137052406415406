import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { SnackbarService } from './snackbar-service.service';
// import { SnackbarService } from './snackbar-service.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorManager {
  constructor(private sessionService: SessionService, private snackbar: SnackbarService ) {}

  detectError(errorResponse: HttpErrorResponse | ErrorEvent | any): void {
    // el mensaje final a mostrar en el dialog o snackbar
    let viewMsg: Array<string>;
    let msg: string = '';
    if (errorResponse instanceof HttpErrorResponse) {
      switch(errorResponse.status) {
        case 462:
          this.goLogin();
          break;
        case 0:
          msg = 'Verifique su conexión';
          break;
        case 404:
          msg = 'Ocurrió un error inesperado';
          break;
        case 500:
          msg = 'Ocurrió un error inesperado';
          break;
        default:
          // mensaje de error en el response
          const errorObj: any = errorResponse.error;
          // mensajes a mostrar
          viewMsg = errorResponse.error?.message;
          msg = viewMsg?.toString();
          break;
      }
    }
  }

  goLogin(): void {
    // this.snackbar.showMessage('Su sesión ha expirado, ingrese nuevamente', 'warning');
    this.sessionService.logout();
  }
}
