import { Injectable, TemplateRef } from '@angular/core';

export interface Toast {
	template: TemplateRef<any>;
	classname?: string;
	delay?: number;
	message?: string;
	type: 'error' | 'success' | 'warning';
}

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: Toast[] = [];
	// standar: any;
	// successTpl: any;
	// dangerTpl: any;
	toastRef: any;
  	sms: string = '';

	show(type: 'error' | 'success' | 'warning', sms: string = '', delay=3500) {
		const toast = this.creatToast(type, sms, delay);
		this.toasts.push(toast);
	}

	creatToast(type: 'error' | 'success' | 'warning', sms: string, delay: number): Toast{
		let className = 'text-light';

		if(type === "error"){
			className += ' bg-danger';
		}
		else if(type === 'success'){
			className += ' bg-success';
		}
		else{
			className += ' bg-warning';
		}

		const toast = {
			template: this.toastRef, 
			classname: className,
			delay: delay,
			message: sms,
			type: type
		}

		return toast;
	}

	remove(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	clear() {
		this.toasts.splice(0, this.toasts.length);
	}

	setRef(toast: any){
		this.toastRef = toast;
	}
}