import {afterNextRender, AfterViewInit, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';

import {FooterComponent} from "./component/footer/footer.component";
import {HeaderComponent} from "./component/header/header.component";
import {GoogleMapsModule} from "@angular/google-maps";
import {
  GoogleTagManagerConfiguration,
  GoogleTagManagerModule,
  GoogleTagManagerService
} from "angular-google-tag-manager";
import {AppModule} from "./app.module";
import {LocalServiceService} from "./services/local-service.service";
import { NgIf, ViewportScroller } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { DashboardService } from './component/dashboard/dashboard.service';
import { ToastComponent } from './component/toast/toast.component';
import { getMessaging, onMessage } from "firebase/messaging";
import { NotificationService } from './services/dashboard/notification.service';
import { MisSolicitudesService } from './component/dashboard/pages/mis-solicitudes/mis-solicitudes.service';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HeaderComponent,FooterComponent,RouterOutlet, GoogleMapsModule,AppModule, ToastComponent,
    NgIf
  //   GoogleTagManagerModule.forRoot({
  //   id: 'GTM-564BL9K',
  // })
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements  OnInit{
  localService= inject(LocalServiceService)
  router= inject(Router)
  unsubscribe$: Subject<any> = new Subject();
  dashBoardService = inject(DashboardService);
  notificationService = inject(NotificationService);
  misSolicitudesService = inject(MisSolicitudesService);


  ngOnInit(): void {
  }
  //linkWhatsapp = 'https://api.whatsapp.com/send/?phone=595974565858&text=Hola%2C+estoy+navegando+tu+p%C3%A1gina+web+y+tengo+una+consulta.&type=phone_number&app_absent=0';
  telef = '595974565858'
  msnWhatsapp = '';
// Google Map
  display: any;
  isBrowser: boolean;

  constructor(
    private  gtmService: GoogleTagManagerService,
    private cd: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    
      afterNextRender(()=>{
         const queryString = window.location.search;
         //console.log('queryString',queryString);
         
         const urlParams = new URLSearchParams(queryString);
         //console.log('home urlParams afterNextRender',urlParams);
         this.localService.addUTMParams(urlParams);
         this.localService.addQueryParams(queryString);
         //Obtenemos mensaje de whatsapp
         this.msnWhatsapp = this.obtenerMensajeWhatsapp(this.localService.getUTMParams('utm_source') || 'web');
           this.cd.detectChanges();

           this.resetScroll();
  
           if (isPlatformBrowser(this.platformId) && navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event) => {
              if (event.data && event.data.type === 'NOTIFICATION_RECEIVED') {
                console.log('Notificación recibida del Service Worker (TS):', event.data.payload);
        
                // Realiza cualquier acción en tu aplicación Angular
                this.handleNotification(event.data.payload);
              }
            });
          }
           
      })
    }

    private handleNotification(payload: any) {
      console.log('Payload de la notificación en background:', payload);
      this.notificationService.setNotificacionSegundoPlano(payload?.data ?? null); //el signal emie al dashboard donde se escuchan los cambios
      this.misSolicitudesService.updateSolicitud(payload?.data ?? null);
      // this.router.navigateByUrl('/mis-solicitudes');
    }

    /**
     * Cara vez que se hace un routing desplaza la pantalla hacia arriba
     */
    resetScroll(){
      this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
        if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    }

    /**
     * Este metodo escucha cuando una notificacion llega en primer plano,
     * Es decir cuando la vista se encuentra en la pagina web actual
     */
    listen() {
      try {
        const messaging = getMessaging();
        console.log('Firebase Messaging inicializado correctamente:', messaging);
    
        // Escuchar mensajes en primer plano
        onMessage(messaging, (payload: any) => {
          console.log('Mensaje recibido en primer plano (app.component): ', payload);
    
          // Muestra una alerta o realiza otra acción
          // alert(`Notificación recibida: ${payload?.notification?.title}`);
        });
    
        console.log('Evento de escucha de notificaciones configurado');
      } catch (error) {
        console.error('Error al inicializar onMessage:', error);
      }
    }
    
    obtenerMensajeWhatsapp(canal:string){
      let mensaje = '';
      switch (canal) {
        case "fb":
        if (this.localService.getUTMParams('utm_medium') === "sm") {
          mensaje = "Hola, vi tu posteo en Facebook y tengo una consulta.";
        } else {
          mensaje = "Hola, vi tu anuncio en Facebook y tengo una consulta.";
        }
        break;

      case "ig":
        if (this.localService.getUTMParams('utm_medium') === "ad") {
          mensaje = "Hola, vi tu anuncio en Instagram y tengo una consulta.";
        } else {
          mensaje = "Hola, vi tu posteo en Instagram y tengo una consulta.";
        }
        break;

      case "google":
        // Por ahora todos los utm_medium = cpc para utm_source = google
        if (this.localService.getUTMParams('utm_medium') === "cpc") {
          mensaje = "Hola, vi tu anuncio en Google y tengo una consulta.";
        } else if (this.localService.getUTMParams('utm_medium') === "video") {
          mensaje = "Hola, vi su video en YouTube y tengo una consulta.";
        } else {
          mensaje = "Hola, vi su anuncio en una página y tengo una consulta.";
        }
        break;

      case "infobip":
        // Por ahora todos los utm_source=sendgrid son utm_medium=em
        mensaje = "Hola, recibí un correo y tengo una consulta.";
        break;

      case "email":
        // Por ahora todos los utm_source=sendgrid son utm_medium=em
        mensaje = "Hola, recibí un correo y tengo una consulta.";
        break;

      default:
        mensaje = "Hola, estoy navegando tu página web y tengo una consulta.";
        this.localService.setUTMParams('utm_source','web');
        this.localService.setUTMParams('utm_medium','orgánico');
        //$utm_source = 'web';
       // $utm_medium = 'orgánico';
        break;


    }
    return mensaje;
  }

  center: google.maps.LatLngLiteral = {

    lat: 22.2736308,

    lng: 70.7512555

  };

  zoom = 6;

  /*------------------------------------------

  --------------------------------------------

  moveMap()

  --------------------------------------------

  --------------------------------------------*/

  moveMap(event: google.maps.MapMouseEvent) {

    if (event.latLng != null) this.center = (event.latLng.toJSON());

  }



  /*------------------------------------------

  --------------------------------------------

  move()

  --------------------------------------------

  --------------------------------------------*/

  move(event: google.maps.MapMouseEvent) {

    if (event.latLng != null) this.display = event.latLng.toJSON();

  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
