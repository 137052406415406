import { Component, inject } from '@angular/core';

import { NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Component({
	selector: 'app-toasts',
	standalone: true,
	imports: [NgbToastModule],
	// imports: [NgbToastModule, NgTemplateOutlet],
	template: `
		@for (toast of toastService.toasts; track toast) {
			<ngb-toast
				[class]="toast.classname"
				[autohide]="true"
				[delay]="3500"
				(hidden)="toastService.remove(toast)"
			>
				<div class="toast-content-flex">
					@if(toast.type === 'success'){
						<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
							<circle cx="12" cy="12" r="10" stroke="#2ECA8B" stroke-width="2" fill="E43F52" />
							<path d="M9 12l2 2 4-4" stroke="#2ECA8B" stroke-width="2" fill="E43F52" />
						</svg>
					}
					@else{
						<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
							<path d="M1 21h22L12 2 1 21z" />
							<text x="12" y="16" font-size="12" text-anchor="middle" fill="#E43F52" font-family="Arial" dy=".3em">!</text>
						</svg>
					}

					{{ toast.message }}
				</div>

				<!-- <ng-template #templateContent>
					<ng-template [ngTemplateOutlet]="toast.template"></ng-template>
				</ng-template> -->
			</ngb-toast>
		}
	`,
	host: {
		'[class]': 'hostClasses',
		'[style]': "'z-index: 1200'"
	  },
	// host: { class: 'toast-container position-fixed top-50 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsContainer {
	toastService = inject(ToastService);
	dashBoardService = inject(DashboardService);

	get hostClasses(): string {
		return `toast-container position-fixed posicion-container end-0 p-3 ${
		  this.dashBoardService?.isDashboard ? 'posicion-container-dashboard' : ''
		}`;
	  }
}
