<div>
    <footer class="footer-dashboard" style="background: #0037C6; color: white;">
      <div class="container">
        <div class="row text-start py-0 justify-content-center ">
          <!-- Columna 1: Logo, descripción y redes sociales -->
          <div class="col-md-3 col-12 logo-footer">
            <a routerLink="/mis-solicitudes" role="button" class="logo-footer d-block">
              <img src="assets/images/logo_hv.png" height="49" alt="Logo">
            </a>
            
          </div>
        </div>
      </div>
    </footer>
  </div>
