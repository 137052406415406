import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { Comunicacion } from '../component/communications/model';

@Injectable({
  providedIn: 'root'
})
export class ConmunicationsService {

  constructor(
      private http: HttpClient,
      private settingService: SettingsService
    ) { }
  
    /**
     * Recupera la lista de documentos de comunicaciones
     * @returns 
     */
   getListComunicaciones(): Promise<Comunicacion []> {
      const comunicacionesList$ = this.http.get<Comunicacion[]>(`${this.settingService.URL_BASE_CREDIT}/pagina-web/comunicaciones/list`)
                  .pipe(map((res: any) => res.data ))
      return firstValueFrom(comunicacionesList$)
    }
}
