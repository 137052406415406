import { LOCALE_ID, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeEsPY from '@angular/common/locales/es-PY';
import { ThousandSeparatorPipe } from '../component/util/thousand-separator.pipe';
import { PhoneFormatPipe } from '../component/util/format-phonenumer.pipe';
import { PrimerNombrePipe } from '../component/util/primer-nombre-apellido';

registerLocaleData(localeEsPY, 'es-PY');

@NgModule({
  declarations: [
    // FullNamePipe,
    // ControlErrorMessagePipe,
    // FontSizeDirective,
    PhoneFormatPipe,
    ThousandSeparatorPipe,
    PrimerNombrePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CommonModule,
    AsyncPipe,
    ReactiveFormsModule,
    ThousandSeparatorPipe,
    PhoneFormatPipe,
    PrimerNombrePipe
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-PY' },
  ]
})
export class SharedModule { }
