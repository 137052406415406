<ng-template #standardTpl> {{toastService.sms}} </ng-template>

<ng-template #toastBar>
	<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
        <path d="M1 21h22L12 2 1 21z" />
        <text x="12" y="16" font-size="12" text-anchor="middle" fill="#E43F52" font-family="Arial" dy=".3em">!</text>
      </svg>
      
	{{toastService.sms}}
</ng-template>

<ng-template #toastSuccess> {{toastService.sms}} </ng-template>

<ng-template #toastError>
	<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
        <path d="M1 21h22L12 2 1 21z" />
        <text x="12" y="16" font-size="12" text-anchor="middle" fill="#E43F52" font-family="Arial" dy=".3em">!</text>
      </svg>
      
	{{toastService.sms}}
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>