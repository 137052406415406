import { AfterViewInit, Component, effect, inject, OnDestroy, Signal, ViewChild } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Router, RouterOutlet } from '@angular/router';
import { NavMenuComponent } from './layout/nav-menu/nav-menu.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import { DashboardService } from './dashboard.service';
import { LoaderComponent } from '../loader/loader.component';
import { FooterDashboardComponent } from './layout/footer-dashboard/footer-dashboard.component';
import { NotificationService } from '../../services/dashboard/notification.service';
import { CreditService } from '../../services/credit.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AprobadoMedioComponent } from '../credit/aprobado-medio/aprobado-medio.component';
import { AprobadoComponent } from '../checkout-extendido/aprobado/aprobado.component';
import { ToastService } from '../../services/toast.service';
import { getMessaging, onMessage} from "firebase/messaging";
import { delay } from 'rxjs';
import { MisSolicitudesService } from './pages/mis-solicitudes/mis-solicitudes.service';
import { SolicitudNotificadaSegundoPlano } from './model';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SharedModule, RouterOutlet, NavMenuComponent, ToolbarComponent, LoaderComponent, FooterDashboardComponent, AprobadoComponent, AprobadoMedioComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnDestroy, AfterViewInit{
  @ViewChild('modalAprobado') modalAprobado:any;
  @ViewChild('modalAprobadoConPagare') modalAprobadoConPagare:any;

  dashBoardService = inject(DashboardService);
  notificacionService = inject(NotificationService);
  creditService = inject(CreditService);
  toastService = inject(ToastService);
  misSolicitudesService = inject(MisSolicitudesService);
  router = inject(Router);
  
  #modalRef: NgbModalRef | null = null;
  solicitud: any | null = null;
  notificacionRecibidaSegundoPlanoSignal: Signal<SolicitudNotificadaSegundoPlano | null> = this.notificacionService.notificacionRecibidaSegundoPlanoSignal;

  constructor(private modalService: NgbModal){
    this.dashBoardService.isDashboard = true;

    effect(async () => {
      this.solicitud = this.notificacionRecibidaSegundoPlanoSignal();

      if(!!this.solicitud && this.solicitud.solicitudEstado === 's'){

        const yaSeNotifico = await this.verificarSiNotificacionLeida(this.solicitud.solicitudId);
        console.log('yaSeNotifico: ', yaSeNotifico);
        if(!yaSeNotifico){
          this.marcarComoLeido(this.solicitud.solicitudId);
          this.onOpenModal(this.solicitud.solicitudEstadoMedioIngreso);
        }
      }
    })
    
    if (typeof document !== 'undefined') {
      //oculta el whatsapp para acceder solo desde el boton correspondiente
      const element = document.querySelector('.btn-wsp') as HTMLElement;
    
      if (element) {
        element.style.display = 'none';
      }
  
      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'absolute';
        footer.style.bottom = '0px';
      }
    }
  }

  ngAfterViewInit(): void {
    this.listen();
  }


  /**
   * Verifica si una solicitud ya se leyo anteriormente
   * @param idSolicitud 
   */
  async verificarSiNotificacionLeida(idSolicitud: string){
    try {
      const dataResp = await this.notificacionService.yaSeNotifico(idSolicitud);
      return dataResp.notificado;
    } catch (error: any) {
      this.toastService.show('error', error.error?.message);
      return false;
    }
    
    
    
  }

  /**
   * Este metodo escucha cuando una notificacion llega en primer plano,
   * Es decir cuando la vista se encuentra en la pagina web actual
   */
  listen() {
    try {
      const messaging = getMessaging();
      console.log('Firebase Messaging inicializado correctamente:', messaging);
  
      // Escuchar mensajes en primer plano
      onMessage(messaging, (payload: any) => {
        console.log('Mensaje recibido en primer plano (dashboard.component): ', payload);
        console.log('payload.data: ', payload?.data);

        this.solicitud = payload?.data

        if(!!this.solicitud && this.solicitud.solicitudEstado === 's'){
          this.marcarComoLeido(this.solicitud.solicitudId);
          this.onOpenModal(this.solicitud.solicitudEstadoMedioIngreso);
          
          this.creditService.solicitudes = [];
        }
        
        this.misSolicitudesService.updateSolicitud(this.solicitud);
        
      });
  
      console.log('Evento de escucha de notificaciones configurado');
    } catch (error) {
      console.error('Error al inicializar onMessage:', error);
    }
  }

  /**
     * realiza la peticion para marcar como leido una solicitud la cual fue desplegada en el modal
     * @param solicitudId 
     */
    marcarComoLeido(solicitudId: string){
  
      this.notificacionService.marcarComoLeido({solicitudId: solicitudId}).pipe(
        delay(1500)
      ).subscribe({
        next: resp => {
  
        },
        error: err => {
          this.toastService.show('error', err.error?.message);
        }
      });
    }

  ngOnDestroy(): void {
    this.dashBoardService.isDashboard = false;
  }

  /***
   * Se envia la solicitud del primer paso del flujo
   */
  onOpenModal(tipo: "pag" | 'bind' | null){
    let modal: any;
    let config: any = { centered: true };

    if(!tipo){
      return;
    }

    if(tipo === 'bind'){
      modal = this.modalAprobado;
    }
    else if(tipo === 'pag'){
      this.creditService.medio = 95;
      this.creditService.codigoRespuesta = 'WS-OK';
      modal = this.modalAprobadoConPagare;
      config = { centered: true };
    }

    this.#modalRef  = this.modalService.open(modal, config)
  }

   /**
   * Si onClose es tru cierra el modal
   * @param onClose recibi un boolean emitido como output
   */
   onCloseModal(onClose: any){
    if(onClose){
      this.#modalRef?.close('aceptar');

      this.cleanData();
    }
  }

   /**
   * Setea nuevamente los datos por defecto
   */
   cleanData(){
    if(this.solicitud?.medioIngreso === 'pag' || this.solicitud?.solicitudEstadoMedioIngreso === 'pag'){
      this.creditService.medio = 0;
      this.creditService.codigoRespuesta = null;
    }

    this.solicitud = null;
  }

  /**
  * Redirige a sucursales
  */
  onVersSucursales(){
    this.router.navigateByUrl('/sucursales');
  }
}
