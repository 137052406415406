import {AfterViewInit, Component, inject, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {NgxSliderModule, Options} from "@angular-slider/ngx-slider";
import {DecimalPipe, isPlatformBrowser, NgClass, registerLocaleData} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgxMaskDirective} from "ngx-mask";
import {CreditService} from "../../services/credit.service";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import localeEs from '@angular/common/locales/es';
import { debounceTime, Subject } from 'rxjs';
registerLocaleData(localeEs);

@Component({
  selector: 'app-simulator',
  standalone: true,
  imports: [NgxSliderModule, ReactiveFormsModule, NgClass,FormsModule , NgxMaskDirective, RouterLink, DecimalPipe],
  templateUrl: './simulator.component.html',
  styleUrl: './simulator.component.css',
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }],
})
export class SimulatorComponent  implements OnInit,AfterViewInit, OnDestroy{
  creditService = inject(CreditService);
  //router = inject(Router);
  montos: any[] = this.creditService.getMontos(1000000,15000000,1000000);
  plazos: any[] = this.creditService.getPlazos();
  creditForm!: FormGroup;
  isBrowser: boolean;
  sliderValue = 12;
  valueMonto: number = 2000000;
  private cuotasDebounceSubject = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId:Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.createCreditForm();
    this.cuotasDebounceSubject
    .pipe(debounceTime(300))
    .subscribe(() => this.getCuotas());
  }

  ngOnInit(): void {

    this.getCuotas();
    //this.getTokenForm();
    this.router.events.forEach(item =>{
      if(item instanceof NavigationEnd){
        console.log("NavigationEnd",item);
        const gtmTag = {
          event: "simulador",
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

  createCreditForm(){
    this.creditForm = this.fb.group({
      monto: this.fb.control<number | null>(this.montos[2].value, Validators.required),
      plazo: this.fb.control<number | null>(this.plazos[1].value, Validators.required),
      cuota: [''],
      cuotaDisplay: [''],
    });
  }

  getCuotas() {
    this.creditService.getCuotas(this.creditForm.value.monto, this.creditForm.value.plazo)
      .subscribe((data:any) => {
        this.creditForm.patchValue({cuota: data.montoCuota});
      });
  }

  getImporteCuota() {
    const formattedCuota = this.creditForm.value.cuota.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return formattedCuota;
  }

  getCant(){
    return this.creditForm.value.plazo;
  }


  valueCuota: number = 5;
  optionsCuota: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 6 },
      { value: 12 },
      { value: 15 },
      { value: 18 },
    ]
  };

  valueImporte: number = 1000000;
  optionsImporte: Options = {
    showTicksValues: false,
    showTicks: false,
    translate: (value: number): string => {
      return 'Gs. ' + value;
    },
    stepsArray: [
      { value: 1000000 },
      { value: 2000000 },
      { value: 3000000 },
      { value: 4000000 },
      { value: 5000000 },
      { value: 6000000 },
      { value: 7000000 },
      { value: 8000000 },
      { value: 9000000 },
      { value: 10000000 },
      { value: 11000000 },
      { value: 12000000 },
      { value: 13000000 },
      { value: 14000000 },
      { value: 15000000 },
    ]
  };

  ngAfterViewInit(): void {
  }

  getCredito() {
    this.creditService.setCreditoSimulado(this.creditForm.value)
    this.router.navigate(['/credito']);
  }


  getClosestValue(value: number): number {
    return this.plazos.reduce((prev: Plazo, curr: Plazo) =>
      Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev
    ).value;
  }



  onSliderChange(event: any): void {
  // Este método se ejecuta mientras el slider está siendo movido
  const currentValue = event.target.value;
  this.sliderValue = this.getClosestValue(Number(currentValue)); // Ajustamos a un valor cercano
  this.creditForm.get('plazo')?.setValue(this.sliderValue);
  this.cuotasDebounceSubject.next(); 
}

getSliderProgress(): string {
  const min = this.plazos[0].value;
  const max = this.plazos[this.plazos.length - 1].value;
  const progress = (this.sliderValue - min) / (max - min) * 100;

  // Devolvemos el progreso como un porcentaje
  return `${progress}%`;
}



onMontoSliderInput(event: any): void {
  const currentValue = event.target.value;
  this.valueMonto = this.getClosestMonto(Number(currentValue)); // Ajustamos al valor más cercano
  this.creditForm.get('monto')?.setValue(this.valueMonto);
  this.cuotasDebounceSubject.next(); 
}

getClosestMonto(value: number): number {
  return this.montos.reduce((prev: any, curr: any) =>
    Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev
  ).value;
}

getMontoSliderProgress(): string {
  const min = this.montos[0].value;
  const max = this.montos[this.montos.length - 1].value;
  const progress = (this.valueMonto - min) / (max - min) * 100;

  // Devolvemos el progreso como un porcentaje
  return `${progress}%`;
}

ngOnDestroy(): void {
  this.cuotasDebounceSubject.complete();
}

}

interface Plazo {
  value: number;
}