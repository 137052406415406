import {Component, inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear()
  constructor() {
  }

  ngOnInit(): void {
  }

}
