<!--bg-transparent MENU PRINCIPAL-->
<nav class="navbar navbar-expand-lg navbar-dark" >
    <div class="container header-container">
  <!--    <a class="navbar-brand" href="#">Credi Agil</a>-->
      <a [routerLink]="['/mis-solicitudes']" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <img src="assets/images/logo_hv.png" alt="CrediAgilLogo">
      </a>
      <button class="navbar-toggler border-0 navbar-inverse"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation">
        <span> <i class="bi bi-three-dots" style=" color: white; font-size:38px;"></i></span>
      </button>

      <!-- MENU -->
      <div class="sidebar offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-body" >
          <ul class="navbar-nav justify-content-center align-items-center fs-6 flex-grow-1 pe-1" data-bs-toggle="offcanvas">
            <li><a [routerLink]="['/mis-solicitudes']" class="nav-link mx-2 active " title="Inicio">Mis solicitudes</a></li>
          </ul>
        </div>

        <div class="offcanvas-body" >
          <ul class="navbar-nav justify-content-center align-items-center fs-6 flex-grow-1 pe-1" data-bs-toggle="offcanvas">
            <li><a (click)="irALaWeb()" class="nav-link mx-2 active " title="Salir">Ir a la Web</a></li>
          </ul>
        </div>

        <div class="offcanvas-body" >
          <ul class="navbar-nav justify-content-center align-items-center fs-6 flex-grow-1 pe-1" data-bs-toggle="offcanvas">
            <li><a (click)="logout()" class="nav-link mx-2 active " title="Salir">Salir</a></li>
          </ul>
        </div>
      </div>

      <div class="user-name">
        {{((authUser$ | async)?.nombre ?? '' | primerNombreApellido) + ' ' + ((authUser$ | async)?.apellido ?? '' | primerNombreApellido)}}
      </div>

      <li class="nav-item dropdown d-sm-none d-lg-block d-none">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="Novedades">
          <i class="text-white bi bi-gear"></i>
        </a>

        <ul class="dropdown-menu">
          <a class="dropdown-item" (click)="logout()" ><i class="bi bi-box-arrow-right"></i> <span class="item-nav-text">Logout</span></a>
          <a class="dropdown-item" (click)="irALaWeb()" ><i class="bi bi-house"></i> <span class="item-nav-text">Ir a la Web</span></a>
        </ul>
      </li>
    </div>
  </nav>
  