import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../settings.service';
import { SolicitudNotificadaSegundoPlano } from '../../component/dashboard/model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  seMostroAlertaDeNotificaciones = false;
  yaSeGuardoToken = signal(false);

  #notificacionRecibida = signal<SolicitudNotificadaSegundoPlano | null>(null);
  notificacionRecibidaSegundoPlanoSignal = this.#notificacionRecibida.asReadonly();

  constructor(
          private router: Router,
          private http: HttpClient,
          private settings: SettingsService,
      ) {
  
      }

  /**
   * Guarda el token en el BE
   * @param payLoad filtro
   * @returns un obserable con 
   */
  saveToken(payLoad: any){
    return this.http.post(`${this.settings.URL_BASE_CREDIT}/checkout/extendido/notificacion/save_token`, payLoad);
  }

  /**
   * Marca la solicitud como leida
   * @param payLoad filtro
   * @returns un observable con la solicitud actualizada
   */
  marcarComoLeido(payLoad: any){
    return this.http.post(`${this.settings.URL_BASE_CREDIT}/checkout/extendido/solicitud/marcar_leido`, payLoad);
  }


  /**
   * Verifica si una notificacion ya se leyo
   * @param idSolicitud id de la solicitud
   */
  yaSeNotifico(idSolicitud: string): Promise<any>{
    const yaSeLeyo$ = this.http.get(`${this.settings.URL_BASE_CREDIT}/checkout/extendido/solicitud/estado_notificacion/${idSolicitud}`);
    return firstValueFrom(yaSeLeyo$);
  }


  /**
   * Setea una notificacion recibida 
   * @param notificacion 
   */
  setNotificacionSegundoPlano(notificacion: any | null){
    this.#notificacionRecibida.set(notificacion);
  }
}
