import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SharedModule } from '../../shared/shared.module';
import { LoaderService } from '../../services/dashboard/loader.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  loading: boolean = false;
  unsubscribe$: Subject<void> = new Subject();

  constructor(private loaderService: LoaderService, private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loaderService.isLoading.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
      this.loading = v;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
