  <div>
    <footer class="footer" style="background: #0037C6; color: white;">
      <div class="container">
        <div class="row text-start py-4">
          <!-- Columna 1: Logo, descripción y redes sociales -->
          <div class="col-md-3 col-12">
            <a href="/" class="logo-footer d-block mb-3">
              <img src="assets/images/logo_hv.png" height="49" alt="Logo">
            </a>
            <p class="mb-3" style="font-size: 14px;">
              En CREDI ÁGIL estamos comprometidos a proporcionarte soluciones que se adapten a tus necesidades y te impulsen hacia el éxito.
            </p>
            <ul class="list-inline">
              <li class="list-inline-item me-2">
                <a target="_blank" href="https://www.facebook.com/Crediagil.py/?locale=es_LA" class="text-white iconoSocial" title="Facebook">
                  <i class="bi bi-facebook fa-lg"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a target="_blank" href="https://www.instagram.com/crediagil.py/?hl=es" class="text-white iconoSocial" title="Instagram">
                  <i class="bi bi-instagram fa-lg"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- Columna 2 -->
          <div class="col-md-2 col-6 espacioColumna">
            <h5>Nosotros</h5>
            <ul class="list-unstyled alturaTexto">
              <li><a href="/nosotros" class="text-white">Sobre CrediÁgil</a></li>
              <li><a href="/app" class="text-white">Nuestra APP</a></li>
              <li><a href="/sucursales" class="text-white">Sucursales</a></li>
              <li><a href="/simulador" class="text-white">Simulador</a></li>
            </ul>
          </div>
          <!-- Columna 3 -->
          <div class="col-md-2 col-6 espacioColumna">
            <h5>Utilidades</h5>
            <ul class="list-unstyled alturaTexto">
              <li><a href="/credito" class="text-white">Solicitá tu crédito</a></li>
              <li><a href="/minicreditos" class="text-white">Minicréditos</a></li>
            </ul>
          </div>
          <!-- Columna 4 -->
          <div class="col-md-2 col-6 espacioColumna">
            <h5>Novedades</h5>
            <ul class="list-unstyled alturaTexto">
              <li><a href="/noticias" class="text-white">Noticias</a></li>
              <li><a href="/comunicaciones" class="text-white">Comunicaciones</a></li>
            </ul>
          </div>
          <!-- Columna 5 -->
          <div class="col-md-2 col-6 espacioColumna">
            <h5>Contacto</h5>
            <ul class="list-unstyled alturaTexto">
              <li><a href="/contacto" style="color: white;">contacto&#64;crediagil.com.py</a></li>
            </ul>
          </div>
          <!-- Columna 6 -->
          <div class="col-md-2 col-6 espacioColumna">
            <h5>Descarga la APP</h5>
            <ul class="list-unstyled alturaTexto">
              <li>
                <a target="_blank" href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL" class="logo-footer d-block mb-3">
                  <img src="assets/images/googleplay.png" class="alturaLogo" alt="Logo">
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="linea-final mt-4" style="text-align: center;">
          <hr style="border: 1px solid white !important; width: 100%;opacity: inherit; margin: 0 auto;">
          <p class="mt-3" style="font-size: 14px; color: white;">CREDI ÁGIL © 2024</p>
        </div>
      </div>
    </footer>
  </div>
  
  
  
  
  <!--<div class="footer">-->
  <!--  <div class="container">-->
  <!--    <div class="row justify-content-center">-->
  <!--      <a href="/" class="logo-footer">-->
  <!--        <img src="assets/images/logo_hv.png" height="49" alt="">-->
  <!--      </a>-->
  <!--      <p>En CREDI ÁGIL estamos comprometidos a proporcionarte soluciones que se adapten a tus necesidades y te impulsen hacia el éxito.</p>-->
  <!--      <br>-->
  <!--      <a href="#"><img src="path/to/facebook-icon.png" alt="Facebook" width="30" height="30"></a>-->
  <!--      <a href="#"><img src="path/to/instagram-icon.png" alt="Instagram" width="30" height="30"></a>-->
  <!--    </div>-->
  <!--  </div>-->
  
  <!--</div>-->

