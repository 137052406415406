import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { SettingsService } from './settings.service';
const BASE_URL = 'https://api.cuotas.cabu.dev/sucursales';
@Injectable({
  providedIn: 'root'
})
export class OfficesService {
  http = inject(HttpClient)
  settingService = inject(SettingsService)
  constructor() { }
  getSucursales():Observable<any> {
    return this.http.get(`${this.settingService.URL_BASE_CREDIT}/pagina-web/sucursales/list`);
  }
}
