import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";
import { Observable, map } from "rxjs";
import { Router } from "@angular/router";
import { SnackbarService } from "./snackbar-service.service";
import { SettingsService } from "../settings.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private settingsSvc: SettingsService,
        private session: SessionService,
    ) { }


    login(data: AuthData) {
        return this.http.post(`${this.settingsSvc.URL_BASE_CREDIT}/checkout/extendido/auth/login`, data);
    }

    logout(): void {
        this.session.logout()
    }

    isUserAuthenticated(): boolean{
        const isUserAuthenticated = this.session.getAccessToken();

        return isUserAuthenticated && isUserAuthenticated !== ''? true : false;
    }

    isUserAuthenticated_v2(): Observable<boolean>{
        return this.session.getSession().pipe(
            map(isAutheticated => {
                return !!isAutheticated
            })
        );
    }
}

export interface AuthData {
    username: string;
    password: string;
    // tokenFCM?: string
    // latitud: number,
    // longitud: number
}

// export interface RegistroDatra {
//     documento_nro: string | any;
//     fecha_nacimiento: string | any;
//     nombre_completo: string | any;
//     nro_socio: string | any;
//     email: string | any;
//     nro_celular: string | any;
//     clave_deseada: string | any;
//     clave_confirmacion: string | any;
// }