import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptorFn
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { tap, finalize, catchError, switchMap, timeout } from 'rxjs/operators';
import { SessionService } from './session.service';
import { ErrorManager } from './error-handler.service';
import { LoaderService } from './loader.service';
import { DashboardService } from '../../component/dashboard/dashboard.service';



export const interceptorSession: HttpInterceptorFn = (req, next) => {

  // no token endpoints
  const noTokenEndpoints = [
    'auth',
    'auth/login',
  ];

  // if (NO_INTERCEPTOR_MESSAGE.some(endpoint => req.url.includes(endpoint))) {
  //   return next(req);
  // }

  const NO_LOADER = [
   'checkout/extendido/notificacion/save_token',
   'checkout/extendido/solicitud/marcar_leido',
   'checkout/extendido/solicitud/estado_notificacion'
  ];


  
  // mensajes a mostrar cuando se realizan peticiones según el verbo HTTP
  const loadingMsg: any = {
    GET: 'Cargando..',
    POST: 'Procesando..',
    PUT: 'Procesando..',
    DELETE: 'Removiendo..',
  };

  // se injecta un servicio para toast
  const sessionSvc = inject(SessionService);
  // const toastSvc = inject(ToastService);

  
  const loaderService = inject(LoaderService);
  const errorManager = inject(ErrorManager);
  const dashboardService = inject(DashboardService);

  
  // const deviceSvc = inject(DeviceService);

  let authReq = req.clone({});

  
  // console.log('dashboardService.isDashboard: ', dashboardService.isDashboard);

  if (dashboardService.isDashboard && !noTokenEndpoints.includes(req.url)) {

    authReq = req.clone({
      setHeaders: {
        authorization: 'Bearer ' + sessionSvc.getAccessToken(),
      }
    });
 
  }

  if (dashboardService.isDashboard && !loaderService.isLoading.value) {
      const shouldNotShowLoader = NO_LOADER.some(url => req.url.includes(url));

      if (!shouldNotShowLoader) {
        loaderService.showLoader();
      }
  
  }


  if(!dashboardService.isDashboard){
    return next(req);
  }
  else{
    return next(authReq).pipe(
      timeout(30000), // valor en milisegundos, 30 seg.
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('interceptor response: ', event);
          loaderService.hideLoader();
        }
      }),
      catchError((error) => {
        loaderService.hideLoader();
        console.log('error: ', error);
        // toastSvc.showMessage(error.error.message, 1, 'error-toast');
        // if (!NO_INTERCEPTOR_MESSAGE.some(endpoint => req.url.includes(endpoint))) {
          errorManager.detectError(error);
        // } 
        
        return throwError(() => error);
      })
    );

  }


}
