import { Component, inject, Input } from '@angular/core';
// import { MatDrawer } from '@angular/material/sidenav';
// import { SharedModule } from '../../../shared/shared/shared.module';
// import { Session, SessionService } from '../../../services/session.service';
import { Observable } from 'rxjs';
import { SharedModule } from '../../../../shared/shared.module';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../services/dashboard/auth.service';
import { Session, SessionService } from '../../../../services/dashboard/session.service';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.css'
})
export class ToolbarComponent {
  @Input() title: string = '';

  sessionService = inject(SessionService);
  authService = inject(AuthService);
  router = inject(Router);

  public authUser$: Observable<Session | null>;

  constructor(){
    this.authUser$ = this.sessionService.getSession();
  }


  /**
   * Invoca el servicio loggout
   */
  logout(){
    this.authService.logout()
  }
  
  /**
   * Invoca el servicio loggout
   */
  irALaWeb(){
    this.router.navigateByUrl('/');
  }
}
