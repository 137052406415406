import { Component, inject, output } from '@angular/core';
import { CreditService } from '../../../services/credit.service';
import { Router } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-aprobado',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './aprobado.component.html',
  styleUrl: './aprobado.component.css'
})
export class AprobadoComponent {
  // medio = 0;
  // codigoRespuesta = '';
  msnWhatsapp = 'Hola, quiero desembolsar mi crédito';
  onVerSucursales = output<boolean>();
  onDescargarApp = output<boolean>();
  // telef = this.creditService.phoneWhatsapp;

  constructor(private router: Router){
    
  }

  /**
   * codigo
   * WS-OK
   * WS-SL
   * WS-PR
   * RO-015
   */
  ngOnInit(): void {
    
    // if(this.creditService.codigoRespuesta !== 'WS-OK'){
    //   this.router.navigateByUrl('/credito');
    // }
    // else{
      // this.medio = this.creditService.medio;

      if (typeof document !== 'undefined') {
        
        //oculta el whatsapp para acceder solo desde el boton correspondiente
        
        const element = document.querySelector('.btn-wsp') as HTMLElement;
        
        if (element) {
          element.style.display = 'none';
        }
        
        // const footer = document.querySelector('.footer') as HTMLElement;
        // if (footer) {
        //   footer.style.position = 'absolute';
        //   footer.style.bottom = '0px';
        // }
        
      }
    }


    irASucursales(){
      this.router.navigateByUrl('/sucursales');
    }
}
