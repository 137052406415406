export const environment = {
    production: false,
    baseUrl: 'https://api.cuotas.cabu.dev/',
    baseUrlCredit: 'https://swca.form.fraccionate.net/v1',
    wpPhone: 'https://api.whatsapp.com/send?phone=595974565858',
    wpMsn: 'Hola, me gustaría obtener más información sobre los créditos que ofrecen',
    firebaseConfig: {
      apiKey: "AIzaSyBuM5mxRdiW4N0RbIXXHJn2MMjoy0It7RU",
      authDomain: "negofin-167d3.firebaseapp.com",
      projectId: "negofin-167d3",
      storageBucket: "negofin-167d3.firebasestorage.app",
      messagingSenderId: "1088907454626",
      appId: "1:1088907454626:web:5e0f213ffbb16c424c4ffb",
      measurementId: "G-ZG86QQ3L42",
      vapidKey: ''
    }
  };
  