@if(!dashBoardService.isDashboard){
  <app-header></app-header>
}

<ng-template [ngIf]="isBrowser">
  <ngbd-toast-global></ngbd-toast-global>
</ng-template>

<router-outlet></router-outlet>

@if(!dashBoardService.isDashboard){
  <app-footer></app-footer>
}
<a href="https://api.whatsapp.com/send/?phone={{telef}}&text={{msnWhatsapp}}.&type=phone_number&app_absent=0"
   class="btn-wsp btn-wsp-pulse" target="_blank">
  <i class="bi bi-whatsapp"></i>
</a>
