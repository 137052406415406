import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

// Registrar el Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js') // Nota: Ruta relativa a la raíz
    .then((registration) => {
      console.log('Service Worker registrado correctamente:', registration);
    })
    .catch((error) => {
      console.error('Error al registrar el Service Worker:', error);
    });
} else {
  console.warn('El navegador no soporta Service Workers.');
}

// Inicializa la aplicación Angular
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));