import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, of, switchMap, take } from 'rxjs';
import { AuthService } from '../../../services/dashboard/auth.service';
import { SessionService } from '../../../services/dashboard/session.service';
import { ToastService } from '../../../services/toast.service';
import { isPlatformBrowser } from '@angular/common';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const sessionService = inject(SessionService);
  const toastService = inject(ToastService);
  const platformId = inject(PLATFORM_ID);

  const isUserAuthenticated$ = authService.isUserAuthenticated_v2();

  return isUserAuthenticated$.pipe(take(1),
      switchMap(isAuthenticated => {
          if(!isAuthenticated){
            return sessionService.verificarLocalStore()
          }else{
            return of(isAuthenticated)
          }
        }),
      map(status => {
        if(status === undefined){
          //localstorage undefined
          return router.createUrlTree(['/mis-solicitudes-tmp'])
        }
        else if (!status) {
          if (isPlatformBrowser(platformId)) {
            toastService.show('warning', 'Necesitas loguearte para acceder al dashboard', 3500);
          }
          return router.createUrlTree(['/solicitud-credito/login'])
        }

        return status
      })
    )
};